import React from 'react';
import IframeComponent from '../../components/iframe';

const IframePage = () => (
  <IframeComponent
    src='https://conservas.herokuapp.com/'
    background='#235cc7'
    item={1}
  />
);

export default IframePage;
